<template>
  <hero />
  <jobs/>
  <videoPlay/>
  <process />
</template>

<script>
import hero from "./hero";
import jobs from "./jobs";
import videoPlay from "./video";
import process from "./process";

export default {
  components: {
    hero,
    videoPlay,
    jobs,
    process,
  },
};
</script>

