<template>
  <div class="hexperience container">
    <h1>Network Process</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed
      fusce tempus purus fringilla leo et arcu. Nulla pharetra, ultricies nulla
      tristique.
    </p>
    <div class="two-column-grid">
      <div>
        <img
          class="image"
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928325/website/images/network/1_xuaqca.png"
          alt="hospitality experience"
        />
      </div>
      <div>
        <h1 class="sub-heading">Search for a vetted professional</h1>
        <p>
          Search our pool of vetted professionals that match your criteria.
        </p>
      </div>
    </div>

    <div class="two-column-grid">
      <div>
        <h1 class="sub-heading">View Professional Profile</h1>
        <p>
          View professional introduction and read reviews from other customers.
        </p>
      </div>
      <div>
        <img
          class="image"
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928323/website/images/network/2_hasfhj.png"
          alt="hospitality experience"
        />
      </div>
    </div>

    <div class="two-column-grid">
      <div>
        <img
          class="image"
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928324/website/images/network/3_w7zlrh.png"
          alt="hospitality experience"
        />
      </div>
      <div>
        <p class="span">THE HOSPITALITY EXPERIENCE</p>
        <h1 class="sub-heading">Consult with Professional</h1>
        <p>
          View professional introduction and read reviews from other customers.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* hospitality experience */
.hexperience {
  margin-top: 150px;
}

.hexperience > h1 {
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.hexperience > p {
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  width: 495px;
  margin: 0 auto;
  margin-bottom: 67px;
}

@media (max-width: 768px) {
  .hexperience > p {
    width: 100%
  }

  .two-column-grid div {
    display: grid;
    place-items: center;
  }
}

.hexperience .two-column-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 100px;
  row-gap: 100px;
  margin-bottom: 108px;
}

.hexperience .two-column-grid .image {
  width: 80%;
  border-radius: var(--border-radius);
  margin: 0 auto;
  object-fit: cover;
}


.hexperience .two-column-grid .span {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #1a1a1a;
  margin-bottom: 19px;
}

.hexperience .two-column-grid h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 25px;
  position: relative;
}


@media (max-width: 768px) {
  .hexperience .two-column-grid h1 {
    font-size: 25px;
  }
}

.hexperience .two-column-grid p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  line-height: 22px;
}
</style>