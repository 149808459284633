<template>
  <div class="mt-10">
    <div class="network-services container">
      
      <p>
        Once you have created a profile, it is important that you maintain it up-to-date. We frequently receive requests from employers looking for employees, and by updating your profile and CV with your latest work experience and acquired skills, you are much more likely to be shortlisted for the many positions that the Hayche Network is handling throughout the African continent.
      </p>

      <h1>Professional Jobs</h1>

      <div class="mt-5 network-services-grid">
        <div class="grid-item" v-for="(i, index) in jobs" :key="index">
          <img :src="i.job_image" alt="platform image" />

          <h5>{{ i.job_title }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobs: [
        {
          id: 1,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928343/website/images/jobs/personal-chef_t12scj.png',
          job_title: "PERSONAL CHEF",
        },
        {
          id: 2,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928341/website/images/jobs/front-office-managers_isiplq.png',
          job_title: "FRONT OFFICE MANAGERS",
        },
        {
          id: 3,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928340/website/images/jobs/quality-control-managers_w1u3jx.png',
          job_title: "QUALITY CONTROL MANAGERS",
        },
        {
          id: 4,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928340/website/images/jobs/security-officers_yljqlz.png',
          job_title: "SECURITY OFFICERS",
        },
        {
          id: 5,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928342/website/images/jobs/administrative-executive_jy8equ.png',
          job_title: "ADMINISTRATIVE EXECUTIVE",
        },
        {
          id: 6,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928344/website/images/jobs/front-desk-officers_lrpqlt.png',
          job_title: "FRONT DESK OFFICERS",
        },
        {
          id: 7,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928341/website/images/jobs/house-keepers_pkuwtf.png',
          job_title: "HOUSE KEEPERS",
        },
        {
          id: 8,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928343/website/images/jobs/cleaners_baksgg.png',
          job_title: "CLEANERS",
        },
        {
          id: 9,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928343/website/images/jobs/personal-chef_t12scj.png',
          job_title: "PERSONAL CHEF",
        },
        {
          id: 10,
          job_image: 'https://res.cloudinary.com/hayche-network/image/upload/v1646928341/website/images/jobs/front-office-managers_isiplq.png',
          job_title: "FRONT OFFICE MANAGERS",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* network services */
.network-services {
  margin-bottom: 155px;
}

.network-services h1 {
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.network-services p {
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  width: 495px;
  margin: 0 auto;
  margin-bottom: 67px;
}

@media (max-width: 768px) {
    .network-services p{
        width: 100%;
    }
    
}

.network-services .network-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  column-gap: 20px;
  row-gap: 50px;
  place-items: center;
}

.network-services .network-services-grid .grid-item {
  width: 162px;
  position: relative;
  transition: background-color 2s, transform 0.5s;
}

.network-services .network-services-grid .grid-item:hover {
  transform: scale(1.05);
  transition: background-color 2s, transform 0.5s;
}

.network-services .network-services-grid .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.network-services .network-services-grid .grid-item h5 {
  font-style: normal;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 21px;
  text-align: left;
  letter-spacing: 0.01em;
  color: #1c1c1c;
  width: 100%;
  text-align: center;
}
/* end of network services */
</style>