<template>
  <!-- hero section -->
  <div class="hero">
    <section>
      <div class="mt-10 mb-10">
      <div class="content">
        <h1 class="sub-heading mt-2 mb-2">Welcome to the most popular hub for hospitality professionals</h1>
        <p class="description">
          We invite you to join the Hayche Network of hospitality and Lifestyle professionals, the fastest-growing employment hub in Africa. To join the Network, all you need to do is to create a profile in our database, which ensures that you receive personalized employment opportunities that best match your skillset. There is no cost to joining!
        </p>

        <div class="cta-grid">
          <!-- Activate once fixed -->
          <!-- <button class="button-pulse" href="https://business.hayche.net">Hire Talent</button>
          <button href="https://app.hayche.net">Join Network</button> -->
        </div>
      </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
/* start of hero */
.hero {
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 97.92%),
    url("https://res.cloudinary.com/hayche-network/image/upload/v1646928323/website/images/network/hero_gxzusb.png");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: grid;
  align-items: center;
}


@media (max-width: 768px) {
  .hero {
    
  }
}

.hero .content {
  width: 700px;
  height: 100%;
  display: grid;
  align-items: center;
}

.hero .content h1 {
  font-style: normal;
  color: white;
}

@media (max-width: 768px) {
}

.hero .content .heading {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
  margin-bottom: 22px;
}

.hero .content .description {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.5;
  color: white;
  margin-bottom: 40px;
}

.hero .content img {
  width: 372px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .hero .content img {
    width: 100%;
  }

  .hero .content {
    width: 100%;
  }

  .hero .content h1 {
    font-size: 40px;
  }
}

.cta-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 50px;
  width: 400px;
}

@media (max-width: 768px) {
  .cta-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 20px;
    width: 100%;
  }
}

.cta-grid button {
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  color: #3a3a3c;
  border: none;
  border-radius: var(--button-border-radius);
  background-color: white;
  padding: 15px;
  cursor: pointer;
}
/* end of hero */
</style>